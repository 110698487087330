import {Bridge, ToJSON, Assert} from "js-vextensions";
import {TriggerInfo} from "../../Store/firebase/fbaConfigs/@TriggerSet_Activator";
import {HostClientChannel, HostClientChannel_shortNames} from "../../Store/main/tools/engine";
import {processID, remoteUserProcessesBridge} from "../../Utils/Bridge/Bridge_RemoteUserProcesses";
import {store} from "../../Store";
import {AppInstanceInfo, GetOwnInstanceInfo} from "../../Utils/Bridge/SiteInstanceBridgeManager";
import {RemoteSiteBridge_Init, RemoteSiteBridge_RegisterFunction, RemoteSiteBridge_Call, RemoteSiteBridge_UnregisterFunction} from "../../Utils/Bridge/Bridge_Preload";
import {liveFBASession, StartHostSession, StopHostSession} from "../FBASession";
import {SessionLog} from "../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";

export class ClientConnection {
	constructor(initialData?: Partial<ClientConnection>) {
		this.VSet(initialData);
	}

	channel: HostClientChannel;
	get ChannelStr() { return ` (through ${HostClientChannel_shortNames[this.channel]} channel)`; }
	clientInfo: AppInstanceInfo;
	//clientProcessID: number;

	bridge: Bridge;
	CreateBridge() {
		// clientConnection.bridge is a level-2 bridge built on top of the level-1 remoteUserProcessesBridge/[nativeBridge+remoteSiteBridge]
		this.bridge = new Bridge({
			receiveChannelMessageFunc_adder: receiveChannelMessageFunc=>{
				const ReceiveChannelMessageFromClient = (hostProcessID: number, channelMessage)=>{
					if (hostProcessID != processID) return;
					SessionLog(`Receiving message from client${this.ChannelStr}: ${ToJSON(channelMessage)}`);
					receiveChannelMessageFunc(channelMessage);
				};
				if (this.channel == HostClientChannel.RealtimeDatabase) {
					remoteUserProcessesBridge.RegisterFunction("HostX_ReceiveChannelMessageFromClient", ReceiveChannelMessageFromClient);
				} else {
					//nativeBridge.Call("remoteSiteBridge.RegisterFunction", "HostX_ReceiveChannelMessageFromClient", ReceiveChannelMessageFromClient);
					//remoteDesktopBridge.RegisterFunction("HostX_ReceiveChannelMessageFromClient", ReceiveChannelMessageFromClient);
					RemoteSiteBridge_RegisterFunction("HostX_ReceiveChannelMessageFromClient", ReceiveChannelMessageFromClient);
					//remoteSiteBridge.RegisterFunction("HostX_ReceiveChannelMessageFromClient", ReceiveChannelMessageFromClient);
				}
			},

			sendChannelMessageFunc: channelMessage=>{
				SessionLog(`Sending message to client${this.ChannelStr}: ${ToJSON(channelMessage)}`);
				// the implementation of ClientX_ReceiveChannelMessageFromHost is in HostConnection.CreateBridge
				if (this.channel == HostClientChannel.RealtimeDatabase) {
					remoteUserProcessesBridge.Call("ClientX_ReceiveChannelMessageFromHost", this.clientInfo.processID, channelMessage);
				} else {
					//nativeBridge.Call("remoteSiteBridge.Call", "ClientX_ReceiveChannelMessageFromHost", this.clientInfo.processID, channelMessage);
					//remoteDesktopBridge.Call("ClientX_ReceiveChannelMessageFromHost", channelMessage);
					RemoteSiteBridge_Call("ClientX_ReceiveChannelMessageFromHost", this.clientInfo.processID, channelMessage);
					//remoteSiteBridge.Call("ClientX_ReceiveChannelMessageFromHost", this.clientInfo.processID, channelMessage);
				}
			},
			channel_wrapBridgeMessage: false,
			channel_stringifyChannelMessageObj: false,
		});

		// used by client to know when host<>client bridge is working, for debugging sleep issues
		this.bridge.RegisterFunction("Ping", ()=>{
			console.log("Received ping call from client...");
			return "Host received ping...";
		});
		this.bridge.RegisterFunction("StartHostSession", ()=>{
			StartHostSession("night");
		});
		this.bridge.RegisterFunction("StopHostSession", (uploadResults: boolean, saveLocalData: boolean)=>{
			StopHostSession(uploadResults, saveLocalData);
		});
		// we use activeHostSessionConfig now instead
		/*this.bridge.RegisterFunction("GetHostSessionConfig", ()=> {
			// if we receive the Host_GetConfig call, and we don't have a meaningful response (ie. live-session config), *delay* our response forever
			// (it's kind of a hack, but this is how we keep our response from potentially pre-empting a response by another instance, which *does* have a live-session config available to send)
			if (fbaCurrentSession == null || !fbaCurrentSession.IsLocal()) {
				/*await SleepAsyncUntil(Number.MAX_SAFE_INTEGER);
				return null;*#/
				return new Promise(()=>{}); // return a promise that never resolves
			}
			return fbaCurrentSession.c;
		});*/

		// the remote-user-processes bridge only ever passes this message from remote site instances (ie. web)
		/*this.bridge.RegisterFunction("Snooze", ()=>{
			console.log("Got snooze call from remote, web!");
			this.Snooze("Snooze (remote, web)");
			return "Applied.";
		});*/

		//clientBridge.RegisterFunction("GetHostConfig", ()=>this.c);
		this.bridge.RegisterFunction("RunTriggerAction", (funcName: string, triggerInfo: TriggerInfo)=>{
			const session = liveFBASession?.AsLocal;
			if (session == null) return;

			/*const pack = session.triggerPackages.find(a=>a.name == funcName);
			if (pack) { // package can be null if the component was disabled
				pack.actionIfLocal(triggerInfo);
			}*/
			// call trigger-action for any matching trigger-packages (eg. so client memory-prompt prototype, triggers for all host memory-prompts)
			const packs = session.triggerPackages.filter(a=>a.name == funcName);
			for (const pack of packs) {
				pack.actionIfLocal(triggerInfo);
			}
		});
		// todo: make sure this is safe from arbitrary code execution
		this.bridge.RegisterFunction("TryCallOnComp", (compTypeName: string, methodName: string, ...methodArgs: any[])=>{
			const session = liveFBASession?.AsLocal;
			if (session == null) return;

			session.TryCallOnComp(compTypeName, methodName, ...methodArgs);
		});
	}
	DestroyBridge() {
		if (clientConnection!.channel == HostClientChannel.RealtimeDatabase) {
			remoteUserProcessesBridge.UnregisterFunction("HostX_ReceiveChannelMessageFromClient");
		} else {
			//nativeBridge.Call("remoteSiteBridge.UnregisterFunction", "HostX_ReceiveChannelMessageFromClient");
			RemoteSiteBridge_UnregisterFunction("HostX_ReceiveChannelMessageFromClient");
			//remoteSiteBridge.UnregisterFunction("HostX_ReceiveChannelMessageFromClient");
		}
	}
}
export let clientConnection: ClientConnection|n;

remoteUserProcessesBridge.RegisterFunction("PrepareForClientConnectionOverWS", (hostInfo: AppInstanceInfo)=>{
	const selfInfo = GetOwnInstanceInfo();
	if (hostInfo.ipAddress != selfInfo.ipAddress || hostInfo.processID != selfInfo.processID) return;
	// don't create websocket-server until client requests we do so (helps prevent clients from previous launches calling not-yet-registered funcs)
	RemoteSiteBridge_Init();
});
remoteUserProcessesBridge.RegisterFunction("ConnectToClient", (hostProcessID: number, clientInfo: AppInstanceInfo, channel: HostClientChannel)=>{
	if (hostProcessID != processID) return;
	ConnectToClient(clientInfo, channel);
});
remoteUserProcessesBridge.RegisterFunction("DisconnectFromClient", (hostProcessID: number)=>{
	if (hostProcessID != processID) return;
	DisconnectFromClient();
});
export function ConnectToClient(clientInfo: AppInstanceInfo, channel: HostClientChannel) {
	if (!store.main.tools.engine.allowClientConnections) return;
	//Assert(clientConnection == null, "Cannot connect to new client when a client is already connected.");
	// gracefully handle closing of existing client connection (for example, client may have needed to be restarted)
	if (clientConnection != null) {
		DisconnectFromClient();
	}

	clientConnection = new ClientConnection({clientInfo, channel});
	clientConnection.CreateBridge();
	console.log("Connected to client:", clientInfo);

	// also notify client of some info it would want to know
	TryNotifyClientOfActiveHostSessionState();
}
export function DisconnectFromClient() {
	Assert(clientConnection != null, "Cannot disconnect from client when none is connected!");
	clientConnection.DestroyBridge();
	clientConnection = null;
	console.log("Disconnected from client.");
}

export function TryNotifyClientOfActiveHostSessionState() {
	clientConnection?.bridge.Call("OnActiveHostSessionConfigSet", liveFBASession?.AsLocal?.config);
}