import {useDroppable} from '@dnd-kit/core';
import {E} from "js-vextensions";
import {StoreAccessor} from "mobx-firelink";
import React from "react";
import {Button, Row, Text, TextInput} from "react-vcomponents";
import {BaseComponent, GetDOM} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {ScrollView} from "react-vscrollview";
import {Observer, RunInAction_Set} from "web-vcore";
import {SetUserEntityTags} from "../../../../Server/Commands/SetUserEntityTags.js";
import {GetEntities} from "../../../../Store/firebase/entities.js";
import {GetJournalEntries} from "../../../../Store/firebase/journalEntries.js";
import {GetTermsInDreamSegment} from "../../../../Store/firebase/journalEntries/@JournalEntry.js";
import {MeID} from "../../../../Store/firebase/users.js";
import {store} from "../../../../Store/index.js";
import {EntityCategory, entityCellAspectRatio} from "../../../../Store/main/tools/journey.js";
import {InAndroid} from "../../../../Utils/Bridge/Bridge_Native.js";
import {DNDInfo} from "../../../@Root/DNDStructures.js";
import {ShowAddEntityDialog} from "../../../Content/Entities/EntityDetailsUI.js";
import {TermCell} from "../TermCell.js";

//const fakeEntity_forAdding = new Entity({name: "fakeEntity_forAdding"});

export function ParseSearchTextRaw(searchText_raw: string) {
	const mustStartWith = searchText_raw.startsWith("^");
	const searchText = searchText_raw.startsWith("^") ? searchText_raw.slice(1) : searchText_raw;
	return {mustStartWith, searchText};
}
export function LowercaseAndSimplifyTextForSearch(text: string) {
	return text.toLowerCase().replace(/[^a-z0-9\- ]/g, "");
	//return text.toLowerCase().replace(/[^a-z0-9]/g, ""); // get rid of hyphens/spaces as well, since we can't currently input spaces from canvas-overlay
}
export function GetEntitiesMatchingSearchText(searchText_raw: string) {
	const {mustStartWith, searchText} = ParseSearchTextRaw(searchText_raw);
	return GetEntities().filter(a=>{
		const simpleName = LowercaseAndSimplifyTextForSearch(a.name);
		const simpleSearchText = LowercaseAndSimplifyTextForSearch(searchText);
		if (mustStartWith) return simpleName.startsWith(simpleSearchText);
		return simpleName.includes(simpleSearchText);
	});
}

@Observer
export class EntitiesPanel_Header extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		return (
			<>
				<SearchBar/>
				<SettingsButton/>
			</>
		);
	}
}

export const GetAllTerms = StoreAccessor(s=>(userID: string|n)=>{
	const journalEntries = GetJournalEntries(userID);
	const allTerms_raw = journalEntries.SelectMany(a=>a.segments).SelectMany(a=>GetTermsInDreamSegment(a, "longText", false));
	const allTerms_deduped = allTerms_raw.Distinct();
	const allTerms_counts = new Map<string, number>();
	for (const term of allTerms_raw) {
		allTerms_counts.set(term, (allTerms_counts.get(term) ?? 0) + 1);
	}
	return {allTerms_raw, allTerms_deduped, allTerms_counts};
});

@Observer
export class EntitiesPanel extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		const uiState = store.main.tools.journey.entities;
		const [actuallyShow, setActuallyShow] = React.useState(false);
		if (!actuallyShow) return <Button text="(show entities panel contents)" onClick={()=>setActuallyShow(true)}/>;

		/*const entities = GetEntitiesMatchingSearchText(uiState.searchText);
		const userTags = GetUser(MeID())?.entityTags?.entities ?? {};
		const entitiesInAnyCategory = entities.filter(entity=>entityCategories_tags_values.some(tag=>userTags[entity._key]?.includes(tag)));
		//const people = entities.filter(a=>a.tags.includes("person"));
		const people = entitiesInAnyCategory.filter(a=>userTags[a._key]?.includes("person"));
		const objects = entitiesInAnyCategory.filter(a=>userTags[a._key]?.includes("object"));
		const concepts = entitiesInAnyCategory.filter(a=>userTags[a._key]?.includes("concept"));*/

		const totalColumns_minus1ForDragZone = uiState.entityCellColumns - 1;
		//const columnsPerShownCategory = cat != null ? totalColumns_minus1ForDragZone : totalColumns_minus1ForDragZone / 3;
		return (
			<ScrollView
				contentStyle={{
					display: "flex", alignItems: "flex-start",
					// leave a right-padding equal to one cell's width, so there's empty space that user can drag on for scrolling (on touch-based devices)
					paddingRight: uiState.entityCellContainerWidth / uiState.entityCellColumns,
				}}
				scrollVBarStyle={E(InAndroid(0) && {pointerEvents: "none"})}
			>
				<EntityCategoryUI category={null} columns={totalColumns_minus1ForDragZone}/>
			</ScrollView>
			//<KeyboardUI/>
		);
	}
}

@Observer
export class SearchBar extends BaseComponent<{}, {}> {
	render() {
		const uiState = store.main.tools.journey.entities;
		const useNativeTextInput = true; // temp; in future, allow both
		if (useNativeTextInput) {
			return <TextInput ml="auto" instant autoCapitalize="none" placeholder="Search..." title={`Add a caret (^) at start to match only from start. (also removes categorization)`}
				value={uiState.searchText} onChange={val=>RunInAction_Set(this, ()=>uiState.searchText = val)}/>;
		}
		
		// todo: complete this branch
		return (
			<Row ml="auto">
				<Text>{uiState.searchText}</Text>
			</Row>
		);
	}
}

/*export type Term_LayoutInfo = {term: string, hitCount: number};
export function GetTermLayoutInfo(term: string, allTerms_raw: string[]): Term_LayoutInfo {
	/*const dreams = GetJournalEntries(MeID());
	const segments = dreams.SelectMany(a=>a.segments);*#/
	const hitCount = allTerms_raw.filter(a=>a == term).length;
	return {term, hitCount};
}*/
export const GetTermHitCount = StoreAccessor(s=>(term: string)=>{
	const {allTerms_counts} = GetAllTerms(MeID());
	return allTerms_counts.get(term) ?? 0;
});

@Observer
class EntityCategoryUI extends BaseComponent<{category: EntityCategory|n, columns: number}, {}> {
	render() {
		let {category, columns} = this.props;
		const {allTerms_raw, allTerms_deduped} = GetAllTerms(MeID());

		//const containerID =  "EntityCategoryUI_" + category;
		const droppableInfo = new DNDInfo({type: "EntityPool", category: category});
		const droppableID = JSON.stringify(droppableInfo);
		const {isOver, setNodeRef} = useDroppable({id: droppableID, data: droppableInfo});

		//const terms_layoutInfos = allTerms_deduped.map(term=>GetTermLayoutInfo(term, allTerms_raw)).OrderByDescending(a=>a.hitCount);
		//const terms_final = terms_layoutInfos.map(a=>a.term); // re-order terms by hit-count
		const terms_final = allTerms_deduped.OrderByDescending(term=>GetTermHitCount(term));

		const terms_dndInfos = terms_final.map((term, index)=>{
			const dndInfo = new DNDInfo({type: "TermCell", parentDroppableInfo: droppableInfo, term, indexInContainer: index});
			return dndInfo;
		});
		//const subsequence_dndIDs = entities_dndInfos.map(a=>JSON.stringify(a));

		return (
			<Row ref={c=>setNodeRef(GetDOM(c) as any)} style={{flex: 33.33, flexWrap: "wrap"}}>
				{terms_final.map((term, i)=><TermCell key={i} index={i} /*droppableInfo={droppableInfo}*/ dndInfo={terms_dndInfos[i]} columns={columns} term={term} forPool={true}/>)}
				<AddEntityButton columns={columns} category={category}/>
			</Row>
		);
	}
}

@Observer
export class SettingsButton extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		const uiState = store.main.tools.journey.entities;

		return (
			<Button p="3px 0"
				mdIcon={"cog"}
				style={E(
					//{flex: 33, lineHeight: "12px"},
					{flex: null, width: 40, height: 40},
					{":hover": ""}, // clear the regular styling for this button on-hover, so custom background-color always shows
					//category == "settings" && {flex: null, width: 40, height: null},
				)}
				onClick={()=>{
					// todo
				}}/>
		);
	}
}

export const entityCategories_tags = {
	people: "person",
	objects: "object",
	concepts: "concept",
};
export const entityCategories_tags_values = Object.values(entityCategories_tags);
@Observer
class AddEntityButton extends BaseComponent<{columns: number, category: EntityCategory|n}, {}> {
	render() {
		let {columns, category} = this.props;
		if (category == null) category = "people";
		const uiState = store.main.tools.journey.entities;
		return (
			<Row style={{width: `${100 / columns}%`, aspectRatio: entityCellAspectRatio, border: "1px solid rgba(255,255,255,.3)"}}>
				<Button text="Add" style={{padding: 0, flex: 1}} onClick={()=>{
					const {searchText} = ParseSearchTextRaw(uiState.searchText);
					ShowAddEntityDialog({
						name: searchText,
						tags: [entityCategories_tags[category!]],
					}, (data, id)=>{
						if (data.tags.length) {
							// show message-box asking if user wants to add the suggested-tags as their own tags
							ShowMessageBox({
								title: "Add suggested-tags?",
								message: `Do you want to accept the suggested-tags for your own use?`,
								cancelButton: true,
								onOK: ()=>{
									new SetUserEntityTags({entityGroup: "entities", entityID: id, entityTags: data.tags}).Run();
								},
							});
						}
					});
				}}/>
			</Row>
		);
	}
}