import {AddSpacesAt_Options, Clone, FromJSON, GetEntries, Timer, ToJSON_Advanced} from "js-vextensions";
import {Button, CheckBox, Column, Row, RowLR, Select, Spinner, Text, TextArea, TextInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {ScrollView} from "react-vscrollview";
import {InfoButton, TextPlus} from "web-vcore";
import {EffectPointer, EffectPointer_ToString, EffectPointerType, NewEffectPointerBasedOnType, ResetEffectPointerBasedOnType, RunConfig} from "../../Store/firebase/@Shared/EffectPointer.js";
import {DialogStyle} from "../../Utils/UI/GlobalStyles.js";
import {GetEffectGroupForAlarmType} from "../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@AlarmConfig.js";
import {SpinnerForPercent} from "../../Utils/ReactComponents/SpinnerForPercent.js";
import {PlaySound_ByContentUri} from "../../Utils/Bridge/Bridge_Native/MediaPlayer.js";
import {GetAudioFilesWithSubpath} from "../../Store/main/cache.js";
import _ from "lodash";

export type EffectPointerChangerFunc = (effectPointer: EffectPointer)=>any;

export class EffectPointerInput extends BaseComponent<{enabled: boolean, value: EffectPointer, onChange: (val: EffectPointer)=>any}, {newValue: EffectPointer}> {
	ComponentWillMountOrReceiveProps(props, forMount) {
		if (forMount || props.value != this.props.value) { // if base-data changed
			this.SetState({newValue: Clone(props.value) || new EffectPointer()});
		}
	}

	render() {
		const {enabled, value, onChange} = this.props;

		// in the text-box, continue displaying the base-value until the user presses "OK" in the dialog
		return (
			<Row style={{flex: 1}}>
				<TextInput editable={false} style={{flex: 1}} value={EffectPointer_ToString(value)}/>
				<Button text="Effect" style={{fontSize: 12, padding: "5px 7px", borderRadius: "0 5px 5px 0"}} onClick={()=>{
					const error = null;

					const boxController = ShowMessageBox({
						title: "Edit effect-pointer", cancelButton: true,
						//messageStyle: DialogStyle({}),
						message: ()=>{
							const {newValue} = this.state;
							boxController.UpdateOptions({okButtonProps: {enabled: enabled && error == null}});
							return <EffectPointerEditor enabled={enabled} value={newValue} onChange={val=>{
								this.SetState({newValue: val});
							}}/>;
						},

						onOK: ()=>{
							// once OK is pressed, send new-value to comp's onChange, triggering base-value update for this component
							if (onChange) onChange(this.GetNewData());
						},
						onCancel: ()=>{
							this.SetState({newValue: Clone(value) || {}});
						},

						extraButtons: ()=>{
							// todo: probably add toggle for whether to play all the effects, or just do a "simple" preview (eg. ignore run-count value)
							return (
								<Row style={{display: "inline-flex", float: "right"}}>
									<Button ml={5} text="Preview" onClick={()=>{
										const pointer = this.GetNewData();
										if (pointer.soundFile) {
											const d = pointer.soundFile;
											const targetFile = GetAudioFilesWithSubpath(d.filePath).Random();
											if (targetFile == null) {
												ShowMessageBox({title: "Preview failed", message: "No audio-files found matching the given file-path."});
												return;
											}
											PlaySound_ByContentUri(targetFile.contentUri, d.volume ?? 1, d.speed_multiplier ?? 0, d.speed_duration ?? 0, d.durationLimit ?? -1, d.loop ?? false);
										} else {
											ShowMessageBox({title: "Preview failed", message: "Only sound-file type is previewable atm."});
										}
									}}/>
								</Row>
							);
						},
					});
				}}/>
			</Row>
		);
	}

	GetNewData() {
		const {newValue: newData} = this.state;
		return Clone(newData) as EffectPointer;
	}
}

const splitAt = 170;

export class EffectPointerEditor extends BaseComponent<{enabled: boolean, value: EffectPointer, onChange: (newValue: EffectPointer, ui: EffectPointerEditor)=>any}, {newValue: EffectPointer, jsonError: Error|n}> {
	ComponentWillMountOrReceiveProps(props, forMount) {
		if (forMount || props.value != this.props.value) { // if base-value changed
			this.SetState({newValue: Clone(props.value) || {}});
		}
	}

	resetErrorTimer = new Timer(5000, ()=>this.SetState({jsonError: null}), 1);
	render() {
		const {enabled, onChange} = this.props;
		const {newValue, jsonError} = this.state;

		const Change = (..._)=>{
			if (onChange) onChange(this.GetNewValue(), this);
			this.Update();
		};

		const newValueJSON_simplified = ToJSON_Advanced(newValue, {addSpacesAt: new AddSpacesAt_Options()});
		return (
			<ScrollView style={DialogStyle({width: 800})}>
				<Column>
					<Row>
						<Text>Type:</Text>
						<Select ml={5} displayType="button bar" enabled={enabled} options={GetEntries(EffectPointerType, "ui")} value={newValue.type} onChange={val=>{
							newValue.type = val;
							ResetEffectPointerBasedOnType(newValue);
							Change();
						}}/>
					</Row>

					{newValue.type == EffectPointerType.soundTag &&
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Sound effect tag:</Text>
						<TextInput enabled={enabled} value={newValue.soundEffectTag} onChange={val=>Change(newValue.soundEffectTag = val)}/>
					</RowLR>}
					{newValue.type == EffectPointerType.soundFile && newValue.soundFile &&
					<>
						<RowLR mt={5} splitAt={splitAt}>
							<TextPlus info={`
								The sound played will be a random sound whose file-path contains the given path substring.
								Example: To find all sounds in a folder, use slashes at start and end, like: "/Alarms/LFAlarms/"
							`.AsMultiline(0)}>File path:</TextPlus>
							<TextInput enabled={enabled} value={newValue.soundFile.filePath} onChange={val=>Change(newValue.soundFile!.filePath = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<TextPlus info={`
								Note: This is applied as a "multiplier" of the volume, ie. this setting does not override other volume-multipliers.
								(other multipliers include the device volume, and the current intensity of a driving entry in the Alarms comp [if applicable])
							`.AsMultiline(0)}>Volume:</TextPlus>
							<Spinner min={0} max={100} enabled={enabled} value={((newValue.soundFile.volume ?? 1) * 100).RoundTo(.01)} onChange={val=>Change(newValue.soundFile!.volume = val / 100)}/>
							<Text>%</Text>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<TextPlus info={`
								If set, the sound will be played at a rate that achieves the given target.
								* Example 1: Multiplier of 2 means sound will be played at double speed.
								* Example 2: Duration of 10, for a 5-second sound, means sound will be played at 50% speed.
							`.AsMultiline(0)}>Speed:</TextPlus>
							<Select options={["Original", "Multiplier", "Duration"]} value={
								newValue.soundFile.speed_multiplier != null ? "Multiplier" :
								newValue.soundFile.speed_duration != null ? "Duration" :
								"Original"
							} onChange={val=>{
								if (val == "Original") {
									newValue.soundFile!.speed_multiplier = null;
									newValue.soundFile!.speed_duration = null;
								} else if (val == "Multiplier") {
									newValue.soundFile!.speed_multiplier = 1;
									newValue.soundFile!.speed_duration = null;
								} else {
									newValue.soundFile!.speed_multiplier = null;
									newValue.soundFile!.speed_duration = 1;
								}
								Change();
							}}/>
							{newValue.soundFile.speed_multiplier != null && <Spinner min={0} enabled={enabled} value={newValue.soundFile.speed_multiplier} onChange={val=>Change(newValue.soundFile!.speed_multiplier = val)}/>}
							{newValue.soundFile.speed_duration != null && <Spinner min={0} enabled={enabled} value={newValue.soundFile.speed_duration} onChange={val=>Change(newValue.soundFile!.speed_duration = val)}/>}
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<TextPlus info={`
								For now at least, this limit is applied per audio-file play. (not per effect-pointer play -- relevant if run-count is >1)
							`.AsMultiline(0)}>Duration limit:</TextPlus>
							<Spinner min={-1} enabled={enabled} value={newValue.soundFile.durationLimit ?? -1} onChange={val=>Change(newValue.soundFile!.durationLimit = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<Text>Loop:</Text>
							<CheckBox enabled={enabled} value={newValue.soundFile.loop ?? false} onChange={val=>Change(newValue.soundFile!.loop = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<TextPlus info={`
								If set to 1 or more, transcriber ignores words in filename word-group X during playback. (Example: "Group1_Group2.wav")
							`.AsMultiline(0)}>Words ignore group:</TextPlus>
							<Spinner min={0} enabled={enabled} value={newValue.soundFile.wordsIgnoreGroup ?? 0} onChange={val=>Change(newValue.soundFile!.wordsIgnoreGroup = val)}/>
						</RowLR>

						<Row mt={10} style={{fontWeight: "bold"}}>Multi-run</Row>
						<RowLR mt={5} splitAt={splitAt}>
							<TextPlus info={`
								How many times to select and run an audio-file matching the given criteria. (each run can select a different file, and they are run sequentially)
							`.AsMultiline(0)}>Run count:</TextPlus>
							<Spinner min={1} enabled={enabled} value={newValue.soundFile.runCount ?? 1} onChange={val=>{
								const soundFile = newValue.soundFile!;
								soundFile.runCount = val;
								soundFile.runConfigs ??= [];
								soundFile.runConfigs.length = val;
								for (let i = 0; i < val; i++) {
									soundFile.runConfigs[i] ??= {} as RunConfig;
								}
								Change();
							}}/>
						</RowLR>
						<Row mt={5}>Run configs:</Row>
						{_.range(0, newValue.soundFile.runCount ?? 0).map(runI=>{
							const runConfig = newValue.soundFile!.runConfigs?.[runI];
							if (runConfig == null) return null; // just wait for user to change run-count, to fix this
							return (
								<RowLR key={runI} ml={10} mt={5} splitAt={splitAt}>
									<TextPlus info={`
										If set, the audio-file selected for this run must include the given subpath. (case insensitive)
									`.AsMultiline(0)}>{runI + 1}) Subpath, include:</TextPlus>
									<TextInput enabled={enabled} value={runConfig.subpathInclude} onChange={val=>Change(runConfig.subpathInclude = val)}/>
									<TextPlus ml={5} info={`
										If set, the audio-file selected for this run must not include the given subpath. (case insensitive)
									`.AsMultiline(0)}>Exclude:</TextPlus>
									<TextInput enabled={enabled} value={runConfig.subpathExclude} onChange={val=>Change(runConfig.subpathExclude = val)}/>
									<TextPlus ml={5} info={`
										If set, a delay will be applied after this run, before the next run starts. (in seconds)
									`.AsMultiline(0)}>Post-delay:</TextPlus>
									<Spinner min={0} step={.1} enabled={enabled} value={runConfig.delay_post ?? 0} onChange={val=>Change(runConfig.delay_post = val)}/>
									<Text>s</Text>
								</RowLR>
							);
						})}
					</>}
					{newValue.type == EffectPointerType.tts && newValue.tts &&
					<>
						<RowLR mt={5} splitAt={splitAt}>
							<Text>Sound tag:</Text>
							<TextInput enabled={enabled} value={newValue.tts.soundTag} onChange={val=>Change(newValue.tts!.soundTag = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<TextPlus info={`
								Note: This is applied as a "multiplier" of the volume, ie. this setting does not override other volume-multipliers.
								(other multipliers include the device volume, and the current intensity of a driving entry in the Alarms comp [if applicable])
							`.AsMultiline(0)}>Volume:</TextPlus>
							<SpinnerForPercent enabled={enabled} value={newValue.tts.volume ?? 1} onChange={val=>Change(newValue.tts!.volume = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<Text>Text:</Text>
							<TextInput enabled={enabled} value={newValue.tts.text} onChange={val=>Change(newValue.tts!.text = val)}/>
						</RowLR>
					</>}
					{newValue.type == EffectPointerType.shakeTag &&
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Shake effect tag:</Text>
						<TextInput enabled={enabled} value={newValue.shakeEffectTag} onChange={val=>Change(newValue.shakeEffectTag = val)}/>
					</RowLR>}
					{newValue.type == EffectPointerType.lightTag &&
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Light effect tag:</Text>
						<TextInput enabled={enabled} value={newValue.lightEffectTag} onChange={val=>Change(newValue.lightEffectTag = val)}/>
					</RowLR>}
					{newValue.type == EffectPointerType.scriptTag &&
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Script effect tag:</Text>
						<TextInput enabled={enabled} value={newValue.scriptEffectTag} onChange={val=>Change(newValue.scriptEffectTag = val)}/>
					</RowLR>}

					<Row mt={10} style={{fontWeight: "bold"}}>Chaining</Row>
					<Row mt={5}>
						<CheckBox text="After effect:" enabled={enabled} value={newValue.afterEffect != null} onChange={val=>{
							if (val) {
								newValue.afterEffect = NewEffectPointerBasedOnType(newValue.type);
							} else {
								newValue.afterEffect = null;
							}
							Change();
						}}/>
						<InfoButton ml={5} text={`If set, a second effect will be "chained" after this one. (only actually works for sound-file type atm)`}/>
						{newValue.afterEffect != null &&
						<EffectPointerInput enabled={enabled} value={newValue.afterEffect} onChange={val=>Change(newValue.afterEffect = val)}/>}
					</Row>

					<Text mt={10} mb={5} style={{fontWeight: "bold"}}>Raw JSON</Text>
					<TextArea enabled={enabled} autoSize={true} value={newValueJSON_simplified} onChange={val=>{
						try {
							const newData = FromJSON(val);
							Object.keys(newValue).forEach(key=>Reflect.deleteProperty(newValue, key));
							newValue.VSet(newData);
						} catch (error) {
							this.SetState({jsonError: error});
							this.resetErrorTimer.Start();
						}
						Change();
					}}/>
					{jsonError && <Text mt={5}>Error parsing JSON: {jsonError.toString()}</Text>}
				</Column>
			</ScrollView>
		);
	}

	GetNewValue() {
		const {newValue} = this.state;
		return Clone(newValue) as EffectPointer;
	}
}