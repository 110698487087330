import {liveFBASession} from "../../../Engine/FBASession.js";
import {EngineSessionComp} from "../../../Engine/FBASession/Components/EngineSessionComp.js";
import {store} from "../../../Store/index.js";
import {nativeBridge} from "../Bridge_Native.js";

export class AudioFileEntry {
	contentUri: string;
	filePath: string;
}
export function GetAudioFileEntries_LowLevel(filePathSubstring: string) {
	return nativeBridge.Call("GetAudioFileEntries", filePathSubstring) as Promise<AudioFileEntry[]>;
}

/*export async function PlaySound_ByContentUri_ForEngineComp(comp: EngineSessionComp|n, contentUri: string, volume_preGlobalMultiplier: number, /** -1 = no limit *#/ durationLimit: number, loop: boolean) {
	PlaySound_ByContentUri_ForLiveSession(contentUri, volume_preGlobalMultiplier, durationLimit, loop);
}*/
export async function PlaySound_ByContentUri_ForLiveSession(
	contentUri: string, volume_preGlobalMultiplier: number,
	/** <=0 = no change */ speed_multiplier: number, /** <=0 = no change */ speed_duration: number,
	/** -1 = no limit */ durationLimit: number, loop: boolean
) {
	const volume_final = volume_preGlobalMultiplier * (liveFBASession?.GetSettingValue(c=>c.general.globalVolumeMultiplier) ?? 1);
	
	// var named this way, on the assumption the api's "volume" represents energy (eg. loudness^2) rather than perceptual-loudness
	const volume_final_asEnergy = Math.pow(volume_final, store.main.settings.audio.loudnessCurve_mediaPlayer);

	await PlaySound_ByContentUri(contentUri, volume_final_asEnergy, speed_multiplier, speed_duration, durationLimit, loop);
}
export async function PlaySound_ByContentUri(
	contentUri: string, volume: number,
	/** <=0 = no change */ speed_multiplier: number, /** <=0 = no change */ speed_duration: number,
	/** -1 = no limit */ durationLimit: number, loop: boolean
) {
	await nativeBridge.Call("PlaySound_ByContentUri", contentUri, volume, speed_multiplier, speed_duration, durationLimit, loop);
}

// todo: clarify/simplify filePathSubstring<>targetSoundPathEnd distinction (filePathSubstring is at media-store querying stage, targetSoundPathEnd is custom filtering of results; but still a bit clunky/underspecified)
/*#*
 * NOTE: In almost all cases, you want to use PlaySound_ByContentUri instead. (using the functions in cache.ts for selecting an audio-file)
 * Note: If no audio-file was played, returns an empty string.
 *#/
/*export async function PlaySound_ByFolderOrPath(filePathSubstring: string, targetSoundPathEnd: string|n, volume: number, /** -1 = no limit *#/ durationLimit: number, loop: boolean) {
	const playedAudio_filePath = (await nativeBridge.Call("PlaySound_ByFolderOrPath", filePathSubstring, targetSoundPathEnd, volume, durationLimit, loop)) as string;
	return playedAudio_filePath;
}*/

export const PlaySound_StopAll_extraListeners = [] as (()=>void)[];
export async function PlaySound_StopAll() {
	PlaySound_StopAll_extraListeners.forEach(a=>a());
	await nativeBridge.Call_NoReturn("PlaySound_StopAll");
}

// high-level
// ==========

/*export const magicTag_soundQuiz = "[sound quiz]";
export const magicTagPrefix_soundPath = "[sound:";
export const GetSoundPathFromTag = (tag: string)=>/\[sound:(.+?)\]/.exec(tag)?.[1];
export const GetDurationLimitFromTag = (tag: string)=>/\[length:(\d+)\]/.exec(tag)?.[1]?.ToFloat();
export const GetLoopFromTag = (tag: string)=>/\[loop:([01])\]/.exec(tag)?.[1]?.ToInt() == 1;
export const GetRunCountFromTag = (tag: string)=>/\[runCount:(\d+)\]/.exec(tag)?.[1]?.ToInt();
export const GetWordsIgnoreGroupFromTag = (tag: string)=>/\[wordsIgnore:(\d+)\]/.exec(tag)?.[1]?.ToInt();

export function IsSoundEffectTagForMediaPlayer(tag: string|n) {
	return tag == magicTag_soundQuiz || (tag != null && GetSoundPathFromTag(tag) != null);
}

export async function PlaySoundEffectTagForMediaPlayer(tag: string|n, intensity: number, session: FBASession) {
	if (tag == magicTag_soundQuiz) {
		let soundStartTime = Date.now();
		const playedAudio_filePath = await PlaySound_ByFolderOrPath(session.c.dreamQuiz.soundFolder, null, intensity, GetDurationLimitFromTag(tag) ?? session.c.dreamQuiz.soundDurationLimit, GetLoopFromTag(tag) ?? true);
		SessionLog(`Played audio file: ${playedAudio_filePath}`);
		Android_VLog(VLogLevel.INFO, `Played audio file: ${playedAudio_filePath}`);

		// registering utterance just after sound-end is *usually* fine (due to transcribe-chunking delay), but proper solution wanted eventually 
		NotifyNonTTSUtteranceRange_FromSoundFile(playedAudio_filePath, GetWordsIgnoreGroupFromTag(tag), soundStartTime);
	} else if (tag && GetSoundPathFromTag(tag)) {
		const runCount = GetRunCountFromTag(tag) ?? 1;

		let canceled = false;
		const listener = ()=>canceled = true;
		PlaySound_StopAll_extraListeners.push(listener);

		for (let i = 0; i < runCount; i++) {
			let soundStartTime = Date.now();
			const playedAudio_filePath = await PlaySound_ByFolderOrPath(GetSoundPathFromTag(tag)!, null, intensity, GetDurationLimitFromTag(tag) ?? session.c.dreamQuiz.soundDurationLimit, GetLoopFromTag(tag) ?? true);
			SessionLog(`Played audio file: ${playedAudio_filePath} @runIndex:${i}`);
			Android_VLog(VLogLevel.INFO, `Played audio file: ${playedAudio_filePath} @runIndex:${i}`);

			// registering utterance just after sound-end is *usually* fine (due to transcribe-chunking delay), but proper solution wanted eventually 
			NotifyNonTTSUtteranceRange_FromSoundFile(playedAudio_filePath, GetWordsIgnoreGroupFromTag(tag), soundStartTime);

			if (canceled) break;
		}
		
		PlaySound_StopAll_extraListeners.Remove(listener);
	} else {
		AssertNotify("Sound-effect tag is not for media-player!");
	}
}*/