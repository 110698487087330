import {AddGlobalStyle} from "react-vextensions";
import {E} from "js-vextensions";
import React from "react";

export const styles = {
	/*page: ES({
		//width: 960, margin: "100px auto",
		flex: "0 1 960px", //margin: "100px auto", maxHeight: "calc(100% - 200px)",
		//margin: "100px auto", padding: 50, background: "rgba(0,0,0,.75)", borderRadius: 10, cursor: "auto",
		//flex: 1,
	}),*/
	vMenuItem: {padding: "3px 5px", borderTop: "1px solid rgba(255,255,255,.1)"},

	// fixes that height:100% doesn't work in safari, when in flex container
	fillParent_abs: {position: "absolute", left: 0, right: 0, top: 0, bottom: 0},
};
export const colors = {
	// navBarBoxShadow: "rgba(70,70,70,.5) 0px 0px 150px",
	// navBarBoxShadow: "rgba(100,100,100,1) 0px 0px 3px",
	navBarBoxShadow: "rgba(100, 100, 100, .3) 0px 0px 3px, rgba(70,70,70,.5) 0px 0px 150px",
	// daytime-mode disables certain features, like alarms (affected options tinted with this light blue)
	nightTint: `rgba(200,200,255,.9)`,
};

AddGlobalStyle(`
.VMenu > div:first-child { border-top: initial !important; }
`);

/* declare global {	function ES<E1,E2,E3,E4,E5,E6,E7,E8>(e1?:E1,e2?:E2,e3?:E3,e4?:E4,e5?:E5,e6?:E6,e7?:E7,e8?:E8):E1&E2&E3&E4&E5&E6&E7&E8; } G({ES});
function ES<E1,E2,E3,E4,E5,E6,E7,E8>(e1?:E1,e2?:E2,e3?:E3,e4?:E4,e5?:E5,e6?:E6,e7?:E7,e8?:E8):E1&E2&E3&E4&E5&E6&E7&E8 { */
// declare global { function ES(...styles): any; } G({ ES });
// same as E(...), except applies extra things for style-objects
export function ES(...styles) {
	const result = E(...styles);

	// prevents {flex: 1} from setting {[minWidth/minHeight]: "auto"}
	if (result.flex) {
		//if (result.flexDirection && result.flexDirection.includes("column")) {
		if (result.minWidth == null) result.minWidth = 0;
		if (result.minHeight == null) result.minHeight = 0;
	}

	return result;
}

// helper, to set up dialog-styling to where it can work on desktop and mobile reasonably well
export function DialogStyle(opts: React.CSSProperties) {
	return {
		/*maxWidth: opts.maxWidth ?? `calc(100% - 40px)`, // dialog-container has hard-coded 20[l] + 20[r] padding
		maxHeight: opts.maxHeight ?? `calc(100% - 32px - 20px - 30px)`, // 32: header, 20: dialog-container padding, 30: dialog buttons-row*/
		// use vw/vh units instead of %, because it works more reliably (%-based max-height was just being ignored fsr)
		maxWidth: opts.maxWidth ?? `calc(100vw - 40px)`, // dialog-container has hard-coded 20[l] + 20[r] padding
		maxHeight: opts.maxHeight ?? `calc(100vh - 32px - 20px - 30px)`, // 32: header, 20: dialog-container padding, 30: dialog buttons-row
		//padding: opts.padding ?? "10px 0", // commented; dialog adds its own 10[t] + 10[b] padding
		...opts,
	};
}

/** Helper to extract padding amounts, in pixels, from either the "padding" field, or the padding left/top/right/bottom fields. */
export function ReadPaddingsFromCSS(opts: React.CSSProperties): {top: number, right: number, bottom: number, left: number} {
	let result = {top: 0, right: 0, bottom: 0, left: 0};

	if (typeof opts.padding == "number") Object.assign(result, {top: opts.padding, right: opts.padding, bottom: opts.padding, left: opts.padding});
	if (typeof opts.padding == "string") {
		let p = opts.padding.split(" ");
		let pFloat = p.length == 1 ? parseFloat(opts.padding) : null;
		if (p.length == 1) Object.assign(result, {top: pFloat, right: pFloat, bottom: pFloat, left: pFloat});
		if (p.length == 2) Object.assign(result, {top: parseFloat(p[0]), right: parseFloat(p[1]), bottom: parseFloat(p[0]), left: parseFloat(p[1])});
		if (p.length == 3) Object.assign(result, {top: parseFloat(p[0]), right: parseFloat(p[1]), bottom: parseFloat(p[2]), left: parseFloat(p[1])});
		if (p.length == 4) Object.assign(result, {top: parseFloat(p[0]), right: parseFloat(p[1]), bottom: parseFloat(p[2]), left: parseFloat(p[3])});
	}
	
	if (!!opts.paddingTop) result.top = parseFloat("" + opts.paddingTop);
	if (!!opts.paddingRight) result.right = parseFloat("" + opts.paddingRight);
	if (!!opts.paddingBottom) result.bottom = parseFloat("" + opts.paddingBottom);
	if (!!opts.paddingLeft) result.left = parseFloat("" + opts.paddingLeft);

	return result;
}