import {observer} from "mobx-react";
import {RunInAction} from "web-vcore";
import moment from "moment";
import {Button, Column, Grid, Row, Text} from "react-vcomponents";
import {EstimateDreamEndTime} from "../../Store/firebase/journalEntries.js";
import {GetJournalEntryAllText, JournalEntry} from "../../Store/firebase/journalEntries/@JournalEntry.js";
import {GetMyGridEntityNames, GetUserHidden} from "../../Store/firebase/users_private.js";
import {store} from "../../Store/index.js";
import {ES} from "../../Utils/UI/GlobalStyles.js";
import {JournalDisplayModeSwitcher} from "./EntryList.js";
import {EntryGridSettingsDropdown} from "./EntryGrid/EntryGridSettingsDropdown.js";
import {MeID} from "../../Store/firebase/users.js";
import {Range} from "js-vextensions";

export const EntryGrid = observer((props: {entries: JournalEntry[]})=>{
	const {entries} = props;
	const uiState = store.main.journal;
	const gridEntityNames = GetMyGridEntityNames();

	let columns = 1;
	const earlyRows = ()=>columns * 2;
	const earlyCellCount = ()=>columns * earlyRows();
	// grow columns until we have enough to fit all cells, using a [1w]x[2h] ratio
	for (; earlyCellCount() < gridEntityNames.length; columns++) {}
	// then remove any rows that are in fact not needed
	const unneededEarlyRows = Math.floor((earlyCellCount() - gridEntityNames.length) / columns);
	const rows = earlyRows() - unneededEarlyRows;
	
	const sizeStyling = {
		width: (1 / columns).ToPercentStr(1),
		height: (1 / rows).ToPercentStr(1), // no need to set height; the cells auto-stretch to share the available height
	};

	return (
		<Column style={ES({height: "100%", flex: 30})}>
			<Column className="clickThrough" style={{height: 40, background: "rgba(0,0,0,.7)", borderRadius: "10px 10px 0 0"}}>
				<Grid single centerY style={{position: "relative", height: 40, padding: "0 10px"}}>
					<Row style={{justifySelf: "left"}}>
						<JournalDisplayModeSwitcher/>
					</Row>
					{/*<Text style={{justifySelf: "center", fontSize: 18}}>Your journal entries</Text>*/}
					<EntryGridSettingsDropdown/>
				</Grid>
			</Column>
			<div style={{flex: 1, minHeight: 0, display: "flex", flexWrap: "wrap", border: "solid black", borderWidth: "0 2px 2px 0"}}>
				{Range(0, columns * rows).map(index=>{
					const entityName = gridEntityNames[index];
					if (entityName == null) return <div key={index} style={{...sizeStyling, border: "1px solid black"}}/>;
					return (
						<JournalGridCell key={index} entityName={entityName} allEntries={entries} sizeStyling={sizeStyling} selected={false}/>
					);
				})}
			</div>
		</Column>
	);
});

const JournalGridCell = observer((props: {entityName: string, allEntries: JournalEntry[], sizeStyling: any, selected: boolean})=>{
	const {entityName, allEntries, sizeStyling, selected} = props;
	const uiState = store.main.journal;
	const userHidden = GetUserHidden(MeID()!);

	type EntryPlusMatch = {entry: JournalEntry, matches: RegExpMatchArray[], extractGroup: number|n};
	const allEntries_withMatchResults: EntryPlusMatch[] = allEntries.map(entry=>{
		const allText = GetJournalEntryAllText(entry);
		let regex = new RegExp(`[^a-z](${entityName.toLowerCase()})[^a-z]`);
		let extractGroup: number|n;
		if (userHidden?.customGridExtractor_enabled) {
			const regexStr_final = (userHidden.customGridExtractor_regex ?? "REGEX_MISSING").replace(/\$entity/g, entityName);
			regex = new RegExp(regexStr_final, "si");
			const groupCount = regexStr_final.split("(").length - 1;
			// extract text from last group as grid cell's content
			if (groupCount >= 1) extractGroup = groupCount;
		}
		return {entry, matches: allText.Matches(regex), extractGroup};
	});
	const entries_withMatchResults = allEntries_withMatchResults.filter(data=>data.matches.length > 0);
	const entries_ordered = entries_withMatchResults.OrderByDescending(data=>{
		const wakeTime = EstimateDreamEndTime(data.entry);
		if (wakeTime == null) return "0";
		const daysSinceEntry = moment().diff(moment(wakeTime), "days").RoundTo(1);
		return [
			data.entry.segments.Any(a=>a.lucid) ? 1 : 0,
			//dream.segments.SelectMany(a=>GetTermsInDreamSegment(a, "both", false)).length,
			99_999 - daysSinceEntry,
		].join("_");
	});

	const entryUI = (data: EntryPlusMatch|n, subindex: number)=>{
		const emptyUI = <div key={subindex} style={{flex: 1}}/>;
		if (data == null) return emptyUI;
		const {entry, matches, extractGroup} = data;
		const lucid = entry.segments.Any(a=>a.lucid);

		let cellContent = entry.segments.filter(a=>a.lucid && a.shortText.trim().length > 0).map(a=>a.shortText).join(" | ") || entry.title;
		if (matches.length && extractGroup != null) {
			cellContent = matches.map(match=>`"${match[extractGroup]}"`).join("... ");
		}

		const wakeTime = EstimateDreamEndTime(entry);
		if (wakeTime == null) return emptyUI;
		const daysSinceEntry = moment().diff(moment(wakeTime), "days").RoundTo(1);
		
		return <Button key={subindex}
			//text={daysSinceEntry + ""}
			text={cellContent}
			style={ES(
				{
					flex: 1, padding: 0, fontSize: 7, lineHeight: "7px",
					wordBreak: "break-all", alignItems: null, justifyContent: null, overflow: "hidden",
				},
				//lucid && {border: "1px solid rgba(255,255,0,.3)"},
				lucid && {color: "rgba(255,255,0,1)"},
				!lucid && {color: "rgba(255,255,255,1)"},
			)}
			onClick={()=>{
				RunInAction("JournalDayCell.onClick", ()=>store.main.journal.selectedJournalEntry = entry._key);
			}}/>;
	};
	
	//const isCurrentDay = moment().date() == props.dayNumber;
	return (
		<Column style={ES(
			sizeStyling,
			{
				//width: "25%", height: (1 / 8).ToPercentStr(1),
				border: "1px solid black",
			},
			//lucid && {border: "2px solid rgba(255,255,0,1)"},
			//isCurrentDay && {border: "solid rgba(0,255,0,1)", borderWidth: "2px"},
		)}>
			<Text style={{fontSize: 10, justifyContent: "center", background: "rgba(0,0,0,.7)"}}>{entityName}</Text>
			{Range(0, uiState.entriesPerGridCell - 1).map(entryIndex=>{
				return entryUI(entries_ordered[entryIndex], entryIndex);
			})}
		</Column>
	);
});