import {BaseComponent} from "react-vextensions";
import {VReactMarkdown, PageContainer} from "web-vcore";
import {ES} from "../../Utils/UI/GlobalStyles.js";

const red = "rgba(255,0,0,.7)";
const green = "rgba(0,255,0,.6)";

const done = `✔`;
const part = `⚙`;
const plan = `🚧`;
/*const done = `<span style="font-family: segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol">
✔</span>`;
const part = `<span style="font-family: segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol">
⚙</span>`;
const plan = `<span style="font-family: segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol">
🚧</span>`;*/

const pageText = `
Welcome to Lucid Frontier!

Lucid dreaming is a life-long passion of mine, and as a software developer, I wanted to build a place where users could ${""
}trial the variety of electronic induction methods, conduct experiments, record their dreams, take part in competitions, etc. in a community of like-minded enthusiasts.

Note that although this website can be accessed from multiple contexts (browser, [desktop app](/more/download), Android app), the functionalities available differ greatly.${""
} For dream-engine use in particular, the [desktop app](/more/download) is the recommended way to access this site.

Here are some of the features implemented or planned... (statuses: ${done}done, ${part}partial, ${plan}planned)

### Experiment with induction methods

${done}: [Tools/Engine](/tools/engine) page provides a highly customizable "dream engine". (with quick config-switching)

${plan}: Listings for non-electronic induction methods. (with distributed result-tracking)

### Variety of input sources

${done}: Webcams and ip-cameras (for motion detection/recording) \\[any usb webcam or ONVIF-compatible ip-cam]

${done}: Gyro/accelerometer devices \\[Muse S; phone support planned]

${done}: EEG headsets (for eye-movement detection/recording) \\[Muse S]

${done}: Mouse clicks/moves, key-presses (including from Bluetooth mice/keyboards)

${part}: Speech-to-text (limited integration at the moment)

${done}: Events from remote computer/phone (sent over network to the host)

### Variety of output options

${done}: Text-to-speech messages

${done}: YouTube video playback (as audio)

${done}: Bluetooth/wifi lights (LIFX)

${done}: Bed-shakers (SonicBomb wireless device, others through custom scripts)

${done}: Custom scripts (anything you can trigger to run using NodeJS scripts)

### Share configs and session-data

${done}: Shareable engine configurations.

${plan}: Shareable session-data. (eeg data, gyro data, etc.)

### Conduct crowd-sourced research

${plan}: Store statistics on induction-method results/statistics among the user-base.

${plan}: Conduct distributed experiments on dream-stabilization, relocation approaches, dream character behavior, etc.

### Advanced dream-journal

${done}: Add dream-journal entries. (very basic at the moment)

${part}: Attach a variety of metadata. (add tags, mark lucid sections, store dream-engine config and results, etc.)

${plan}: Search and graph data from public journal entries, to discern trends.

### Lucid-dreaming competitions

${plan}: Provide a place where "lucid dreaming competitions" can be organized.

${plan}: Automated dream-goal idea submissions and selection.

${plan}: Automated entry of dreams by users during competitions.

${plan}: Fun additional features, such as fancy leaderboards.
`;

export class HomeUI2 extends BaseComponent<{}, {}> {
	render() {
		return (
			<PageContainer scrollable={true}>
				<style>{`
				.homePageContainer > span > h1 {
					margin-top: 0;
				}
				.homePageContainer > div p {
					margin-block-start: 0; /* default: 1em */
					margin-block-end: 0; /* default: 1em */
				}
				`}</style>
				<article className="selectableAC">
					<VReactMarkdown source={pageText} className="selectable homePageContainer" style={ES({marginBottom: 0})}
						// commenting these options for now (perhaps not supported after presumed underlying-lib change)
						//escapeHtml={false} // needed so emojis can have font specified
						//parserOptions={{commonmark: true}}
					/>
				</article>
			</PageContainer>
		);
	}
}