import {GetSelectedProposalID} from "firebase-feedback";
//import {GetSelectedSubforumID, GetSelectedThreadID} from "firebase-forum";
import {Assert, VURL, ModifyString} from "js-vextensions";
import {StoreAccessor} from "mobx-firelink";
import {RootState, store} from "../../Store";
import {GetPage, GetSubpage} from "../../Store/main.js";
import {GetSelectedJournalEntryID} from "../../Store/main/journal.js";
import {MaybeLog, Page} from "web-vcore";
import ReactGA from "react-ga";
import {GetSelectedUserID} from "../../Store/firebase/users.js";
import {InAndroid} from "../../Utils/Bridge/Bridge_Native.js";

// for subpages, each page's first one is the default
export const pageTree = new Page({}, {
	users: new Page({simpleSubpages: false, actionIfActive: s=>s.main.users.selectedUserID = null}),
	//forum: new Page(),
	feedback: new Page({}, {
		proposals: new Page({actionIfActive: s=>s.feedback.main.proposals.selectedProposalID = null as any}),
		//roadmap: new Page(),
		//neutrality: new Page(),
	}),
	timeline: new Page({}, {
		sessions: new Page({actionIfActive: s=>s.main.timeline.sessions.selectedSession = null}),
		timeline: new Page(),
		activities: new Page(),
	}),
	more: new Page({}, {
		download: new Page(),
		links: new Page(),
		admin: new Page(),
		tests: new Page(),
		"sign-in-helper": new Page(),
	}),
	home: new Page({}, {
		home: new Page(),
		//about: new Page(),
		privacy: new Page(),
	}),
	journal: new Page({simpleSubpages: false, actionIfActive: s=>s.main.journal.selectedJournalEntry = null}),
	effects: new Page({}, {
		sounds: new Page(),
		shakes: new Page(),
		lights: new Page(),
		scripts: new Page(),
		//bundles: new Page(),
	}),
	content: new Page({}, {
		scenes: new Page(),
		entities: new Page(),
		stories: new Page(),
	}),
	tools: new Page({}, {
		engine: new Page(),
		monitor: new Page(),
		training: new Page(),
		pest: new Page(),
		journey: new Page(),
	}),
	settings: new Page(),
	profile: new Page(),
});
export const pages = pageTree.children;

export function PushHistoryEntry() {
	// history.pushState({}, document.title, GetNewURL());
	history.pushState({}, document.title, window.location.href);
}

export function NormalizeURL(url: VURL) {
	const result = url.Clone();
	if (!Object.keys(pageTree.children).Contains(result.pathNodes[0])) {
		result.pathNodes.Insert(0, "home");
	}
	if (result.pathNodes[1] == null && pages[result.pathNodes[0]]?.DefaultChild) {
		result.pathNodes.Insert(1, pages[result.pathNodes[0]]?.DefaultChild);
	}
	return result;
}

export function GetCurrentURL_SimplifiedForPageViewTracking() {
	//let result = VURL.Current();
	const result = GetNewURL(false);

	/*let mapID = GetOpenMapID();
	let onMapPage = result.Normalized().toString({domain: false}).startsWith("/global/map");
	if (mapID && onMapPage) {
		let nodeID = GetFocusedNodeID(mapID);
		let node = nodeID ? GetNode(nodeID) : null;
		//if (result.pathNodes.length == 1) {
		/*if (result.Normalized().toString({domain: false}).startsWith("/global/map") && result.pathNodes.length == 1) {
			result.pathNodes.push("map");
		}*#/
		if (node) {
			result = result.Normalized();
			result.pathNodes.push(GetCrawlerURLStrForNode(node));
		}
	}*/
	return result;
}

// loading
// ==========

export function GetLoadActionFuncForURL(url: VURL) {
	return (store: RootState)=>{
		// if in android, don't do any loading based on url, because the url is the same every time (and is just the domain/root-path)
		if (InAndroid(0)) return;

		url = NormalizeURL(url);
		const page = url.pathNodes[0];
		store.main.page = page;
		const subpage = url.pathNodes[1];
		if (url.pathNodes[1] && pageTree.children[page]?.simpleSubpages && pageTree.children[page]?.ChildKeys.includes(subpage)) {
			store.main[page].subpage = subpage;
		}

		// load query-vars
		if (url.GetQueryVar("extra")) store.main.urlExtraStr = url.GetQueryVar("extra") == "null" ? null : url.GetQueryVar("extra");
		if (url.GetQueryVar("env")) store.main.envOverride = url.GetQueryVar("env") == "null" ? null : url.GetQueryVar("env");
		if (url.GetQueryVar("db")) store.main.dbOverride = url.GetQueryVar("db") == "null" ? null : url.GetQueryVar("db");
		if (url.GetQueryVar("dbVersion")) store.main.dbVersionOverride = url.GetQueryVar("dbVersion") == "null" ? null : url.GetQueryVar("dbVersion");
		if (url.GetQueryVar("analyticsEnabled")) store.main.analyticsEnabled = url.GetQueryVar("analyticsEnabled") == "true";

		if (page == "users") {
			//const subpageInURL = url.pathNodes[1] != null;
			const entryID = url.pathNodes[1] || null; // null needed, else reducer complains
			//if (subpage == "users" && subpageInURL) {
			store.main.users.selectedUserID = entryID!;
		}

		if (page == "feedback") {
			if (subpage == "proposals") {
				const idStr = url.pathNodes[2];
				const idStrMatch = idStr && idStr.match(/([A-Za-z0-9_-]+)$/);
				const proposalID = idStrMatch ? idStrMatch[1] : null;
				store.feedback.main.proposals.selectedProposalID = proposalID!;
			}
		}

		/*if (url.pathNodes[0] == "forum") {
			const subforumStr = url.pathNodes[1];
			if (subforumStr != "*") {
				const subforumIDMatch = subforumStr && subforumStr.match(/([0-9]+)$/);
				const subforumID = subforumIDMatch ? subforumIDMatch[1].ToInt() : null;
				store.forum.selectedSubforum = subforumID;
			}

			const threadStr = url.pathNodes[2];
			const threadIDMatch = threadStr && threadStr.match(/([0-9]+)$/);
			const threadID = threadIDMatch ? threadIDMatch[1].ToInt() : null;
			store.forum.selectedThread = subforumID;
		}*/

		if (url.pathNodes[0] == "journal") {
			/*const idStr = url.pathNodes[1];
			const idStrMatch = idStr && idStr.match(/([0-9]+)$/);
			const entryID = idStrMatch ? idStrMatch[1].ToInt() : null;*/
			const entryID = url.pathNodes[1] || null; // null needed, else reducer complains
			store.main.journal.selectedJournalEntry = entryID;
		}

		if (url.pathNodes[0] == "content" && url.pathNodes[1] == "stories") {
			const entryID = url.pathNodes[2] || null; // null needed, else reducer complains
			store.main.content.selectedStory = entryID;
		}
	};
}

// saving
// ==========

//g.justChangedURLFromCode = false;
export const GetNewURL = StoreAccessor(s=>(includeMapViewStr = true)=>{
	//let newURL = VURL.Current();
	/*let oldURL = VURL.Current(true);
	let newURL = new URL(oldURL.domain, oldURL.pathNodes);*/

	const newURL = new VURL();
	const page = GetPage();
	newURL.pathNodes.push(page);

	//if (GetSubpage() == "qRiWU7QmQIqKVIaMWrArOw") { debugger; }
	var subpage = GetSubpage();
	if (pageTree.children[page]?.simpleSubpages && subpage) {
		newURL.pathNodes.push(subpage);
	}

	// query vars
	if (s.main.urlExtraStr) newURL.SetQueryVar("extra", s.main.urlExtraStr);
	if (!s.main.analyticsEnabled && newURL.GetQueryVar("analytics") == null) newURL.SetQueryVar("analytics", "false");
	if (s.main.envOverride) newURL.SetQueryVar("env", s.main.envOverride);
	if (s.main.dbOverride) newURL.SetQueryVar("db", s.main.dbOverride);
	if (s.main.dbVersionOverride) newURL.SetQueryVar("dbVersion", s.main.dbVersionOverride);

	if (page == "users") {
		if (GetSelectedUserID()) {
			newURL.pathNodes.push(`${GetSelectedUserID()}`);
		}
	}

	if (page == "feedback") {
		const proposalID = GetSelectedProposalID();
		if (proposalID) newURL.pathNodes.push(`${proposalID}`);
	}

	/*if (page == "forum") {
		const subforumID = GetSelectedSubforumID();
		const threadID = GetSelectedThreadID();
		if (subforumID) newURL.pathNodes.push(`${subforumID}`);
		else if (threadID) newURL.pathNodes.push("*");

		if (threadID) newURL.pathNodes.push(`${threadID}`);
	}*/

	if (page == "journal") {
		const entryID = GetSelectedJournalEntryID();
		if (entryID) newURL.pathNodes.push(`${entryID}`);
	}

	if (page == "content" && subpage == "stories") {
		const storyID = s.main.content.selectedStory;
		if (storyID) newURL.pathNodes.push(`${storyID}`);
	}

	// a default-child is only used (ie. removed from url) if there are no path-nodes after it
	/*if (subpage && subpage == rootPageDefaultChilds[page] && newURL.pathNodes.length == 2) newURL.pathNodes.length = 1;
	if (page == "home" && newURL.pathNodes.length == 1) newURL.pathNodes.length = 0;*/
	// nowadays, we only remove the page and subpage for the /home/home path (it's not worth making urls more brittle just for slightly shorter urls)
	if (page == "home" && subpage == "home") newURL.pathNodes.length = 0;

	Assert(!newURL.pathNodes.Any(a=>a == "/"), `A path-node cannot be just "/". @url(${newURL})`);

	return newURL;
});

export function DoesURLChangeCountAsPageChange(oldURL: VURL, newURL: VURL) {
	if (oldURL == null) return true;
	if (oldURL.PathStr() != newURL.PathStr()) return true;

	return false;
}

export function RecordPageView(url: VURL) {
	// let url = window.location.pathname;
	if (PROD) {
		// todo: ms if react-ga is not initialized yet, we buffer up these commands, then run them once it is initialized
		ReactGA.set({page: url.toString({domain: false})});
		ReactGA.pageview(url.toString({domain: false}) || "/");
	}
	MaybeLog(a=>a.pageViews, ()=>`Page-view: ${url}`);
}